import React, { useEffect, useRef } from "react";

interface Message {
  message: string;
  sender: "model" | "user";
}
const ChatMessage = ({ message, sender }: Message) => {
  const isUser = sender !== "model";

  return (
    <div
      className={`flex p-2 flex-start w-full ${
        isUser ? "flex-row-reverse" : "flex-row"
      }`}
    >
      {isUser ? (
        <div className="flex font-bold justify-center items-center h-10 min-w-10 text-white bg-green-600 rounded-full">
          <p>Y</p>
        </div>
      ) : (
        <div className="flex font-bold justify-center items-center h-10 min-w-10 text-white bg-blue-600 rounded-full">
          <p>U</p>
        </div>
      )}
      <div className={`px-2 rounded-lg gap-2 flex flex-col ${isUser ? 'items-end' : 'items-start'}`}>
        <div
          className={
            "prose prose-sm text-md m-0 font-serif " +
            (!isUser ? "text-left" : "text-right")
          }
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <time className="text-xs text-gray-600">
          {new Date().toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            day: "numeric",
            month: "short",
            hour12: true,
          })}
        </time>
      </div>
    </div>
  );
};

export default function Conversation() {
  const newMessage = useRef<HTMLInputElement>(null);
  const messageArea = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = React.useState<Message[]>([]);

  const [isTyping, setIsTyping] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isTyping) return;
    const message = newMessage.current?.value;
    if (!message) return;
    newMessage.current!.value = "";
    setMessages([
      ...messages,
      {
        message,
        sender: "user",
      },
    ]);
    setIsTyping(true);
    return fetch(`https://genai-tools.skcript.com/api/ullam`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, history: messages }),
    })
      .then((res) => res.json())
      .then(({ response: message }) => {
        setMessages((_messages) => [
          ..._messages,
          {
            message: message,
            sender: "model",
          },
        ]);
        setIsTyping(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsTyping(false));
  };

  useEffect(() => {
    if (messages.length === 0) return;
    localStorage.setItem("messages", JSON.stringify(messages));
    // Scroll to the bottom of the chat
    if (messageArea.current) {
      messageArea.current.scrollTop = messageArea.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const _messages = localStorage.getItem("messages");
    if (_messages && _messages.length > 0) {
      try {
        setMessages(JSON.parse(_messages));
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  return (
    <>
      <div className="bg-amber-50 fixed w-full top-0 border-b text-center p-2 grid grid-cols-3 items-center">
        <div></div>
        <div>
          <h1 className="flex-1 font-serif font-semibold">ULLAM</h1>
        </div>
        <div className="flex justify-end">
          {!!messages.length && (
            <button
              className="bg-gray-500 text-white py-1 px-2 text-xs rounded-full"
              onClick={() => {
                setMessages([]);
                localStorage.removeItem("messages");
              }}
            >
              Clear
            </button>
          )}
        </div>
      </div>
      <div className="h-full w-full ">
        <div className="flex mx-auto max-w-3xl flex-grow-1 flex-col justify-between">
          <div className="flex-grow-1 px-2 pt-[50px] pb-[75px]" ref={messageArea}>
            {messages.length ? (
              <div className="flex flex-col gap-6 py-4">
                {messages.map((message, idx) => (
                  <ChatMessage key={idx} {...message} />
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <div className="bg-gray-100 border border-gray-200 max-w-2xl text-center rounded-lg p-2 flex flex-col items-center text-left m-6">
                  <img src="/logo.svg" alt="logo" className="w-20" />
                  <p>
                    Hi, I'm Ullam, Here to listen to you and help you feel
                    better. You can start by saying how you feel and what bothers you today.
                  </p>
                </div>
              </div>
            )}
            {isTyping && (
              <div className="flex p-2">
                {/* Three dot animation for typing  */}
                <div className="animate-ping w-1 h-1 bg-gray-500 rounded-full"></div>
                <div className="animate-ping w-1 h-1 delay-100 bg-gray-500 rounded-full ml-2"></div>
                <div className="animate-ping w-1 h-1 delay-300 bg-gray-500 rounded-full ml-2"></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full px-2">
        <form
          className="bg-white max-w-3xl mx-auto rounded-lg mb-2 shadow p-2 flex items-center gap-2"
          onClick={handleSubmit}
        >
          <input
            type="text"
            disabled={isTyping}
            className="flex-1 p-2 rounded-lg focus:ring-0 focus:outline-none"
            placeholder={`I'm here to hear you, say how you feel...`}
            ref={newMessage}
          />
          <button
            type="submit"
            disabled={isTyping}
            className="bg-blue-500 text-white py-1 px-4 rounded-full disabled:opacity-50"
          >
            Talk
          </button>
        </form>
      </div>
    </>
  );
}
