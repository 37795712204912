import React from 'react';
import './App.css';
import Conversation from './components/conversation/Conversation';

function App() {
  return (
    <Conversation />
  );
}

export default App;
